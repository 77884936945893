
<div class="container jmserviceprint-redirection">
  <div class="jmserviceprint-redirection-img mt-5">
    <img src="../assets/images/travaux.png" class="img-fluid"  alt="">
  </div>
  <div class="jmserviceprint-redirection-texte mt-5">
    <p>Jmserviceprint vous propose de réaliser vos flyers, calendriers, vos numérisations et tout plein de service à tres bientot </p>
  </div>

</div>
