<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">JmServicePrint</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Accueil</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Actualité</a>
        </li>
        <li class="nav-item">
          <a class="nav-link ">Contact</a>
        </li>
        <li class="nav-item">
          <a class="nav-link ">A propos</a>
        </li>
      </ul>
      <ul class="nav justify-content-end" >
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">connecter</a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown2">
            <li><a class="dropdown-item" routerLink="/creation">Créer un compte</a></li>
            <li><a class="dropdown-item" routerLink="/creation">reset mot de passe</a></li>
            <li><a class="dropdown-item" routerLink="/creation">Votre profile</a></li>
          </ul>
        </li>

      </ul>
    </div>
  </div>
</nav>
